import { selector } from 'recoil'
import configState from './config'

const user = selector({
  key: 'user',
  get: ({ get }) => {
    const config = get(configState)
    return config?.user
  },
})

export default user
