import { motion } from 'framer-motion'
import GoogleSSOButton from './GoogleSSOButton/GoogleSSOButton'
import FacebookSSOButton from './FacebookSSOButton/FacebookSSOButton'
import TwitterSSOButton from './TwitterSSOButton/TwitterSSOButton'
import MicrosoftSSOButton from './MicrosoftSSOButton/MicrosoftSSOButton'
import Logo from '@/shared/components/Logo/Logo.svg?react'
import styles from './Login.scss'

const Login = () => {
  return (
    <div className={styles.root}>
      <motion.div
        className={styles.logoContainer}
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: [0, 1, 1, 1], scale: 1.2 }}
        transition={{ duration: 1.5 }}
      >
        <a href='https://causemos.co' target='_blank' rel='noreferrer'>
          <Logo />
        </a>
      </motion.div>

      <motion.div
        className={styles.ssoButtons}
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: [0, 0, 1], y: 0 }}
        transition={{ duration: 1.5, delay: 0.5 }}
      >
        <GoogleSSOButton />
        <FacebookSSOButton />
        <TwitterSSOButton />
        <MicrosoftSSOButton />

        <div className={styles.whatIsCausemos}>
          <a href='https://causemos.co' target='_blank' rel='noreferrer'>
            What is Causemos™?
          </a>
        </div>
      </motion.div>
    </div>
  )
}

export default Login
