import GoogleLogo from './GoogleLogo.svg?react'
import styles from './GoogleSSOButton.scss'

const GoogleSSOButton = () => {
  return (
    <a className={styles.root} href='/auth/google'>
      <GoogleLogo />

      <span className={styles.text}>Continue with Google</span>
    </a>
  )
}

export default GoogleSSOButton
