import { memo, useCallback } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import Logo from '@/shared/components/Logo/Logo.svg?react'
import Avatar from './Avatar/Avatar'
import styles from './Header.scss'

import { PROFILE_PATH, SETTINGS_PROFILE_PATH, SETTINGS_USER_PATH } from '@/constants/pathConstants'

const HEADER_PATHS = [PROFILE_PATH, SETTINGS_PROFILE_PATH, SETTINGS_USER_PATH]
const SHOW_BACK_BUTTON_PATHS = [SETTINGS_PROFILE_PATH, SETTINGS_USER_PATH]

const Header = () => {
  const { pathname } = useLocation()
  const history = useHistory()

  const showHeader = HEADER_PATHS.includes(pathname)
  const showBackButton = SHOW_BACK_BUTTON_PATHS.includes(pathname)

  const handleOnBackButtonClick = useCallback(() => {
    history.goBack?.()
  }, [history])

  return (
    !!showHeader && (
      <div className={styles.root}>
        <div className={styles.headerButton}>
          {showBackButton && (
            <button className={styles.backButton} onClick={handleOnBackButtonClick}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          )}
          &nbsp;
        </div>

        <div className={styles.logo}>
          <Logo />
        </div>

        <Link className={styles.headerButton} to={SETTINGS_USER_PATH}>
          <Avatar />
        </Link>
      </div>
    )
  )
}

export default memo(Header)
