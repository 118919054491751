import { useEffect } from 'react'
import { RecoilRoot } from 'recoil'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify'
import useHotjar from 'react-use-hotjar'
import Layout from '@/components/Layout/Layout'
import getConfig from '@/shared/utilities/config'

import 'react-toastify/dist/ReactToastify.css'

const queryClient = new QueryClient()

const App = () => {
  const config = getConfig()
  const { initHotjar } = useHotjar()

  useEffect(() => initHotjar(config.hotjarSiteId, 6), [config, initHotjar])

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <Layout />
        <ToastContainer />
      </RecoilRoot>
    </QueryClientProvider>
  )
}

export default App
