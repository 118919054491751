import { memo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import analytics from '../../utilities/analytics'

const RouteTracker = () => {
  const location = useLocation()
  useEffect(() => {
    analytics.pageview({ path: location.pathname, location })
  }, [location])

  return <div />
}

export default memo(RouteTracker)
