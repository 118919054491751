import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import styles from './TwitterSSOButton.scss'

const TwitterSSOButton = () => {
  return (
    <a className={styles.root} href='/auth/twitter'>
      <FontAwesomeIcon icon={faTwitter} />

      <span className={styles.text}>Continue with Twitter</span>
    </a>
  )
}

export default TwitterSSOButton
