import Screen from '@/shared/components/Screen/Screen'
import styles from './UserSettings.scss'

const UserSettings = () => {
  return (
    <Screen className={styles.root}>
      <h1>My Profile</h1>

      <a className={styles.outlineBtn} href='/auth/logout'>
        Logout
      </a>
    </Screen>
  )
}

export default UserSettings
