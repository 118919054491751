import { useRecoilValue } from 'recoil'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStripe } from '@fortawesome/free-brands-svg-icons'
import configState from '@/shared/atoms/config'
import Screen from '@/shared/components/Screen/Screen'
import styles from './ProfileSettings.scss'

const ProfileSettings = () => {
  const config = useRecoilValue(configState)
  const { stripeConnected, stripeConnectUrl } = config

  return (
    <Screen className={styles.root}>
      <h1>Edit Impact Profile</h1>

      {!stripeConnected && (
        <a className={classnames(styles.outlineBtn, styles.connect)} href={stripeConnectUrl}>
          <span>Connect to</span> <FontAwesomeIcon icon={faStripe} size='3x' />
        </a>
      )}

      {!!stripeConnected && (
        <div className={classnames(styles.outlineBtn, styles.connect)}>
          <FontAwesomeIcon icon={faStripe} size='3x' /> <span>connected</span>
        </div>
      )}
    </Screen>
  )
}

export default ProfileSettings
