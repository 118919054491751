import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SocialLink = ({ social, icon, prefix = '' }) => {
  if (social === null) {
    return null
  }

  return (
    <li>
      <a href={social.link} target='_blank' rel='noreferrer'>
        <FontAwesomeIcon icon={icon} /> <span>{`${prefix}${social.title}`}</span>
      </a>
    </li>
  )
}

SocialLink.propTypes = {
  social: PropTypes.object,
  icon: PropTypes.object,
  prefix: PropTypes.string,
}

export default SocialLink
