import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import styles from './FacebookSSOButton.scss'

const FacebookSSOButton = () => {
  return (
    <a className={styles.root} href='/auth/facebook'>
      <FontAwesomeIcon icon={faFacebook} />

      <span className={styles.text}>Continue with Facebook</span>
    </a>
  )
}

export default FacebookSSOButton
