import { memo } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import RouteTracker from '@/shared/components/RouteTracker/RouteTracker'
import Header from '@/components/Header/Header'
import Login from '@/screens/Login/Login'
import Profile from '@/screens/Profile/Profile'
import ProfileSettings from '@/screens/ProfileSettings/ProfileSettings'
import UserSettings from '@/screens/UserSettings/UserSettings'
import styles from './RootRouter.scss'

import {
  BASE_PATH,
  LOGIN_PATH,
  PROFILE_PATH,
  SETTINGS_PROFILE_PATH,
  SETTINGS_USER_PATH,
} from '@/constants/pathConstants'

const RootRouter = () => (
  <div className={styles.root}>
    <BrowserRouter basename={BASE_PATH}>
      <RouteTracker />
      <Header />

      <Route
        render={({ location }) => (
          <AnimatePresence initial={false}>
            <Switch location={location} key={location.pathname}>
              <Route path={LOGIN_PATH} component={Login} />

              <Route exact path={PROFILE_PATH} component={Profile} />
              <Route path={SETTINGS_PROFILE_PATH} component={ProfileSettings} />
              <Route path={SETTINGS_USER_PATH} component={UserSettings} />
            </Switch>
          </AnimatePresence>
        )}
      />
    </BrowserRouter>
  </div>
)

export default memo(RootRouter)
