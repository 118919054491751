import { memo } from 'react'
import PropTypes from 'prop-types'
import styles from './ImpactItem.scss'

const ImpactItem = ({ item }) => (
  <div className={styles.root}>
    <img className={styles.avatar} src={item.image_url} />
    <div className={styles.totalImpact}>{item.total_impact}</div>
    <div className={styles.name}>{item.name}</div>
  </div>
)

ImpactItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default memo(ImpactItem)
