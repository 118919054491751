import { useRecoilValue } from 'recoil'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faGlobe } from '@fortawesome/pro-regular-svg-icons'
import { faInstagram, faFacebookF, faTwitter, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons'
import Screen from '@/shared/components/Screen/Screen'
import tenantState from '@/shared/atoms/tenant'
import userState from '@/shared/atoms/user'
import ImpactItem from './ImpactItem/ImpactItem'
import SocialLink from './SocialLink/SocialLink'
import styles from './Profile.scss'

import { SETTINGS_PROFILE_PATH } from '@/constants/pathConstants'

const Profile = () => {
  const tenant = useRecoilValue(tenantState)
  const user = useRecoilValue(userState)

  const hasNoTenants = user.tenants.length === 0
  const belongsToMultipleTenants = user.tenants.length > 1

  const showSocialLinks = !!(
    tenant?.website ||
    tenant?.instagram ||
    tenant?.facebook ||
    tenant?.twitter ||
    tenant?.linkedin ||
    tenant?.youtube
  )

  if (hasNoTenants) {
    return <Screen className={styles.root}>No impact profiles.</Screen>
  }

  return (
    <Screen className={styles.root}>
      <div className={styles.tenant}>
        <img className={styles.tenantLogo} src={tenant.logo_url} alt='' />
        {!!belongsToMultipleTenants && (
          /* TODO: add screen for selecting tenants and replace A tag with Link component */
          <a className={styles.tenantSelector} href='#'>
            <FontAwesomeIcon icon={faChevronDown} />
          </a>
        )}
      </div>

      <div className={styles.stats}>
        <div className={styles.stat}>
          <div className={styles.statValue}>{tenant.supporters_count}</div>
          <div className={styles.statLabel}>Supporters</div>
        </div>
        <div className={styles.stat}>
          <div className={styles.statValue}>{tenant.posts_count}</div>
          <div className={styles.statLabel}>Posts</div>
        </div>
      </div>

      {!!tenant.biography && <p className={styles.biography}>{tenant.biography}</p>}

      {!!showSocialLinks && (
        <ul className={styles.socialLinks}>
          <SocialLink social={tenant.website} icon={faGlobe} />
          <SocialLink social={tenant.instagram} icon={faInstagram} prefix='@' />
          <SocialLink social={tenant.facebook} icon={faFacebookF} prefix='@' />
          <SocialLink social={tenant.twitter} icon={faTwitter} prefix='@' />
          <SocialLink social={tenant.linkedin} icon={faLinkedinIn} prefix='/' />
          <SocialLink social={tenant.youtube} icon={faYoutube} />
        </ul>
      )}

      <div className={styles.impactItems}>
        {tenant.impact_items.map((item) => (
          <ImpactItem key={item.id} item={item} />
        ))}
      </div>

      <Link className={styles.outlineBtn} to={SETTINGS_PROFILE_PATH}>
        Edit Impact Profile
      </Link>
    </Screen>
  )
}

export default Profile
