import MicrosoftLogo from './MicrosoftLogo.svg?react'
import styles from './MicrosoftSSOButton.scss'

const MicrosoftSSOButton = () => {
  return (
    <a className={styles.root} href='/auth/microsoft'>
      <MicrosoftLogo />

      <span className={styles.text}>Continue with Microsoft</span>
    </a>
  )
}

export default MicrosoftSSOButton
