import { memo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import { motion } from 'framer-motion'
import styles from './Screen.scss'

const Screen = forwardRef(({ className = '', children, snapToBottom = false }, ref) => {
  const history = useHistory()

  const initialX = history.action === 'POP' ? '-75%' : '75%'
  const exitX = history.action === 'POP' ? '75%' : '-75%'

  return (
    <motion.div
      className={classnames(styles.root, className)}
      initial={{ opacity: 0, x: initialX, pointerEvents: 'none' }}
      animate={{ opacity: 1, x: 0, pointerEvents: 'auto' }}
      exit={{ opacity: 0, x: exitX, pointerEvents: 'none' }}
      transition={{ duration: 0.3 }}
    >
      <div ref={ref} className={classnames(styles.content, snapToBottom && styles.snapToBottom)}>
        <div className={styles.inner}>{children}</div>
      </div>
    </motion.div>
  )
})

Screen.displayName = 'Screen'

Screen.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  snapToBottom: PropTypes.bool,
}

export default memo(Screen)
