function cleanParams(params) {
  return Object.entries(params).reduce((cleanedParams, [key, value]) => {
    if (value !== null && typeof value !== 'undefined') {
      cleanedParams[key] = value
    }
    return cleanedParams
  }, {})
}

const gtag = (...args) => {
  window.gtag && window.gtag(...args)
}

const event = ({ category, action, label, value, nonInteraction = false }) => {
  gtag(
    'event',
    action,
    cleanParams({
      event_category: category,
      event_label: label,
      value: value,
      non_interaction: nonInteraction,
    })
  )
}

const click = ({ action, label, value = null }) => {
  const params = {
    action,
    category: 'Campaign Clicks',
    label,
  }

  if (Number.isInteger(value) && value >= 0) {
    params.value = value
  }

  event(params)
}

const pageview = ({ path, location = window.location, title = document.title }) => {
  gtag('event', 'page_view', {
    page_path: path,
    page_location: location,
    page_title: title,
  })
}

export default {
  gtag,
  click,
  event,
  pageview,
}
