import { memo } from 'react'
import { useRecoilValue } from 'recoil'
import userState from '@/shared/atoms/user'
import styles from './Avatar.scss'

const Avatar = () => {
  const user = useRecoilValue(userState)

  return (
    <div className={styles.root}>
      <img src={user.avatar} alt='' />
    </div>
  )
}

export default memo(Avatar)
