import { memo, useEffect } from 'react'
import { useEffectOnce } from 'react-use'
import RootRouter from '../RootRouter/RootRouter'

const Layout = () => {
  const setAppHeight = () => {
    document.documentElement?.style?.setProperty?.('--app-height', `${window.innerHeight}px`)
  }

  useEffectOnce(() => {
    setAppHeight()
  })

  useEffect(() => {
    window.addEventListener('resize', setAppHeight)

    return () => {
      window.removeEventListener('resize', setAppHeight)
    }
  })

  return <RootRouter />
}

export default memo(Layout)
